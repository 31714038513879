const enums = require("../../Enums");
const AddShowcaseRequest = require('../../AddShowcaseRequest')

class AddLayout3Request extends AddShowcaseRequest {

  /**
   *
   * @param sortKey
   * @param itemCount
   * @param showingItemCount
   * @param shopifyCollectionId
   * @return {AddLayout3Request}
   */
  setBody(sortKey, itemCount, showingItemCount, shopifyCollectionId) {
    this.sortKey = sortKey || ''
    this.itemCount = parseInt(itemCount)
    this.showingItemCount = parseFloat(showingItemCount)
    this.shopifyCollectionId = shopifyCollectionId || ''
    return this
  }

  /**
   *
   * @return {object}
   */
  toParams() {
    return this.toParamsForAdd({
      sort_key: this.sortKey,
      item_count: this.itemCount,
      showing_item_count: this.showingItemCount,
      source_type: enums.source_type.SHOPIFY_COLLECTION,
      source_data: {
        shopify_collection_id: this.shopifyCollectionId
      }
    })
  }
}

module.exports = AddLayout3Request;
