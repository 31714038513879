<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="title">{{ t('title') }}
            <span v-if="isHeaderTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_title"
            type="text"
            name="title"
            id="title"
            :disabled="!state.header_visible"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="subTitle">{{ t('rightTitle') }}
            <span v-if="isHeaderRightTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_right_title"
            type="text"
            name="subTitle"
            id="subTitle"
            :disabled="!state.header_visible || !state.header_right_visible"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          v-model="state.header_right_shopify_collection_id"
          :items="collections"
          :disabled="!state.header_visible || !state.header_right_visible"
          :label="t('headerRightShowcaseCollections')"
          :required="isHeaderRightShopifyCollectionIdRequired"
        />
      </div>
      <div class="col-lg-4" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.header_visible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>
      <div class="col-lg-4" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.header_right_visible"
            :label="t('rightHeaderIsVisible')"
            :disabled="!state.header_visible"
          />
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('showingItemCount') }}</label>
              <input
                type="number"
                min="2.01"
                max="3.99"
                step="0.01"
                class="form-control"
                v-model="state.showing_item_count"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('itemCount') }}</label>
              <a-radio-group v-model:value="state.item_count">
                <a-radio :value="8">8</a-radio>
                <a-radio :value="12">12</a-radio>
                <a-radio :value="16">16</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <TagsInput
                v-model="state.shopify_collection_id"
                :items="collections"
                :label="t('showcaseCollections')"
                :required="isShopifyCollectionIdRequired"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('sort.sort') }}</label>
              <a-select
                ref="select"
                v-model:value="state.sort_key"
                style="width: 100%"
              >
                <a-select-option value="DEFAULT">{{
                    t('sort.default')
                  }}
                </a-select-option>
                <a-select-option value="CREATED_AT_DESC">{{
                    t('sort.backToOld')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_ASC">{{
                    t('sort.increasedPrice')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_DESC">{{
                    t('sort.decreasingPrice')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_DESC">{{
                    t('sort.alphabeticalAZ')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_ASC">{{
                    t('sort.alphabeticalZA')
                  }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-wrap">
              <a
                :disabled="isFormInvalid"
                @click="onSubmit"
                class="btn btn-success"
              >{{ t('create.create') }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import AddLayout3Request from '../../../service/RestApi/ShowcaseRequest/Add/AddLayout3Request'

export default {
  components: { TagsInput, MySwitch },
  props: {
    collections: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { router, restApi, t, successNotify, errorNotify, emitter } =
      useGlobal()

    const collections = ref(props.collections)

    const state = reactive({
      type: 'LAYOUT3',
      header_right_type: 'SHOPIFY_COLLECTION',
      header_title: '',
      header_visible: true,
      header_right_title: '',
      header_right_visible: true,
      header_right_shopify_collection_id: '',
      source_type: 'SHOPIFY_COLLECTION',
      item_count: 8,
      showing_item_count: 2.5,
      sort_key: 'DEFAULT',
      sort_dir: 'ASC',
      shopify_collection_id: '',
    })
    // Required checkers
    const isHeaderTitleRequired = computed(() => {
      return ! $v.value.header_title[0].$response
    })

    const isHeaderRightTitleRequired = computed(() => {
      return ! $v.value.header_right_title[0].$response
    })

    const isHeaderRightShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.header_right_shopify_collection_id[0].$response
    })

    const isShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.shopify_collection_id[0].$response
    })

    // Invalid chekers
    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header_title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header_right_title.$error
    })

    const rules = {
      header_title: [requiredIf(() => state.header_visible)],
      header_right_title: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      header_right_shopify_collection_id: [
        requiredIf(() => state.header_right_visible && state.header_visible),
      ],
      shopify_collection_id: [required],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    const onSubmit = () => {
      const addLayout3Request = new AddLayout3Request(
        'LAYOUT3',
        state.header_title,
        state.header_visible,
        state.header_right_title,
        state.header_right_visible,
        state.header_right_shopify_collection_id,
      ).setBody(
        state.sort_key,
        state.item_count,
        state.showing_item_count,
        state.shopify_collection_id,
      ).toParams()

      restApi.showcaseAdd(addLayout3Request).then((response) => {

        successNotify(t('showcaseCreated'), t('showcaseCreatedDescription'))

        emitter.emit('showcase-item-added', response.getId())
        
      }).catch((error) => {

        errorNotify(t('showcaseNotCreated'), t('showcaseNotCreatedDescription') + error)

      })
    }

    return {
      state,
      collections,
      router,
      restApi,
      t,
      successNotify,
      errorNotify,
      cancel,
      onSubmit,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,

      isHeaderTitleRequired,
      isHeaderRightTitleRequired,
      isHeaderRightShopifyCollectionIdRequired,
      isShopifyCollectionIdRequired,
    }
  },
}
</script>
